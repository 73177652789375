html {
  font-family: 'Work Sans', sans-serif !important;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Scroll Bar width*/
.scrollable::-webkit-scrollbar {
  width: 0.5rem;
}

.scrollableHorizontal::-webkit-scrollbar {
  height: 0.5rem;
}

/* Map Details Card Track*/
.scrollable::-webkit-scrollbar-track {
  border-radius: 5.875rem;
  background: #C9C9C9;
  margin: .5rem;
}

/* Map Details Card Handle*/
.scrollable::-webkit-scrollbar-thumb {
  background-color: #0D806C;
  border-radius: 5.875rem;
  border: 0.18rem solid #C9C9C9;
}

/* Sectors Scroll Track */
.scrollableSectors::-webkit-scrollbar-track {
  border-radius: 5.875rem;
  background: #ffffff;
  margin: .5rem;
}

/* Sector Scroll Handle */
.scrollableSectors::-webkit-scrollbar-thumb {
  background-color: #FA915C;
  border-radius: 5.875rem;
  border: 0.125rem solid #ffffff;
}

.leaflet-touch .leaflet-bar {
  display: flex;
  flex-direction: column;
  background: transparent;
  border: 0 !important;
  gap: 0.5rem;
}

.leaflet-touch .leaflet-bar a {
  font-size: 1.125rem;
  color: #0D806C;
  box-shadow: 0 0.0625rem 0.25rem rgba(37, 37, 37, 0.3);
}

.leaflet-bar a:not(.leaflet-disabled):hover {
  color: #0D806C;
}

.leaflet-touch .leaflet-bar a:first-child,.leaflet-touch .leaflet-bar a:last-child {
  border-radius: 0.25rem !important;
}

.leaflet-control-attribution {
  display: none;
}

/* Input range - Mobile */
input[type=range] {
  -webkit-appearance: none;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  background: #c9c9c9;
  border-radius: 50%;
}

input[type=range]::-webkit-slider-thumb {
  border: 1px solid #0D806C;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -20px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #c9c9c9;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  background: #c9c9c9;
  border-radius: 50%;  
}

input[type=range]::-moz-range-thumb {
  border: 1px solid #0D806C;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 40px 0;
  color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: #2a6495;
  border-radius: 2.6px;
}

input[type=range]::-ms-fill-upper {
  background: #c9c9c9;
  border-radius: 2.6px;
}

input[type=range]::-ms-thumb {
  border: 1px solid #0D806C;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
}

input[type=range]:focus::-ms-fill-lower {
  background: #c9c9c9;
}

input[type=range]:focus::-ms-fill-upper {
  background: #c9c9c9;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
