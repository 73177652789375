@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600;700&display=swap');

/* clears the ‘X’ from Internet Explorer & Google Chrome */
input[type='search']::-ms-clear {
  opacity: 0;
  width: 0;
  height: 0;
}
input[type='search']::-ms-reveal {
  opacity: 0;
  width: 0;
  height: 0;
}
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  opacity: 0;
}

#tooltip > div:first-child {
  display: flex;
  gap: 0 5px
}

#tooltip > div:first-child p {
  margin: 0;
}

#tooltip > div {
  margin: 5px 0;
}

#tooltip > div p {
 margin-top: 5px;
}
